/* LoadingScreen.css */

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 9999;
}

.loading-container {
  text-align: center;
  display: flex;
  flex-direction: column; /* Ensure the logo and text are stacked vertically */
  align-items: center;  /* Align the items to the center */
}

.loading-logo {
  width: 200px;  /* Increased logo size */
  height: 200px;  /* Increased logo size */
}

.loading-text {
  font-size: 30px;  /* Adjusted font size */
  font-weight: 700;  /* Bold font */
  margin-top: 20px;  /* Space between logo and text */
  color: #007bff;
  background: linear-gradient(to right, #007bff, #00c6ff, #007bff);  /* Gradient background */
  -webkit-background-clip: text;  /* Apply gradient to text */
  color: transparent;  /* Make text color transparent to show the gradient */
  font-family: 'Poppins', sans-serif;  /* Beautiful font */
  text-transform: uppercase;  /* Make it uppercase */
  letter-spacing: 2px;  /* Add spacing between letters */
  animation: fadeIn 1s ease-out;
}

.loading-bubble {
  margin-top: 30px;  /* Adjusted spacing between text and bubble */
  display: flex;
  justify-content: center;
  align-items: center;
}

.bounce {
  width: 12px;
  height: 12px;
  margin: 6px;
  background-color: #007bff;
  border-radius: 50%;
  animation: pulse 1.2s infinite ease-in-out;
}

.bounce:nth-child(2) {
  animation-delay: -0.4s;
}

.bounce:nth-child(3) {
  animation-delay: -0.2s;
}

@keyframes pulse {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
